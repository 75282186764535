<template>
  <div>
    <router-link v-if="!row.children" :to="row.url ? row.url : ''">
      <el-menu-item v-if="!row.children" :index="row.id.toString()">
        <span slot="title" class="flex align-item-center"><a-icon :type="row.icon" class="mr8" />{{ row.name }}</span>
      </el-menu-item>
    </router-link>
    <el-submenu v-else :index="row.id.toString()">
      <template slot="title">
        <a-icon :type="row.icon" class="mr8" />
        <span slot="title">{{ row.name }}</span>
      </template>
      <template v-for="item in row.children">
        <item-menu :row="item" />
      </template>
    </el-submenu>
  </div>
</template>
<script>
  export default {
    name: 'itemMenu',
    props: {
      row: {
        type: Object,
        default:() => {}
      }
    },
    mounted() {
      // this.$c(this.row)
    }
  }
</script>
<style lang="scss" scoped>
  .dropMenu {
     .el-submenu__title {
      height: auto;
      line-height: inherit;
      padding: 8px;
    }
  }
</style>