<template>
  <a-drawer
    :visible="show"
    width="400px"
    title="配置"
    @close="show = false">
    <div class="overflowY-auto overflowX-hidden" style="height: calc(100vh - 120px);">
      <div class="text-error bg-error2 py8 px16 txt-14 mb20">
        主题配置可实时预览效果，设置更改并不会保存，请复制配置粘贴到 src/setting.js 文件
      </div>
      <a-form-data ref="form" v-model="form" :setting="setting" :render-data="renderData">
        <a-col :span="24" slot="link" class="mb16">
          <div style="border: 1px solid #f1f1f1"></div>
        </a-col>
        <a-col :span="24" slot="link2" class="mb16">
          <div style="border: 1px solid #f1f1f1"></div>
        </a-col>
        <a-col :span="24" slot="button">
          <a-form-model-item label="">
            <a-button type="primary" @click="$common.copyText(JSON.stringify(form))">复制配置</a-button>
          </a-form-model-item>
        </a-col>
      </a-form-data>
    </div>
  </a-drawer>
</template>
<script>
  export default {
    name: 'ChangeSetting',
    props: {
      value: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        show: false,
        loading: false,
        form: {},
        setting: {
          labelAlign: 'left',
          labelCol: { span: 8 },
          wrapperCol: { span: 16 }
        },
        renderData: {
          showHeader: {
            label: '头部菜单',
            renderName: 'aRadioGroup',
            options: [
              { label: '开启', value: true },
              { label: '关闭', value: false },
            ],
          },
          headerBackgroundColor: {
            label: '头部背景颜色',
            renderName: 'aColor'
          },
          headerTextColor: {
            label: '头部文字颜色',
            renderName: 'aColor'
          },
          link: {
            renderType: 'slot'
          },
          showMenu: {
            label: '左边菜单',
            renderName: 'aRadioGroup',
            options: [
              { label: '开启', value: true },
              { label: '关闭', value: false },
            ],
          },
          menuBackgroundColor: {
            label: '菜单背景颜色',
            renderName: 'aColor'
          },
          menuTextColor: {
            label: '菜单文字颜色',
            renderName: 'aColor'
          },
          menuActiveTextColor: {
            label: '选中文字颜色',
            renderName: 'aColor'
          },
          link2: {
            renderType: 'slot'
          },
          tabBackground: {
            label: 'Tab背景颜色',
            renderName: 'aColor'
          },
          tabTextColor: {
            label: 'Tab文字颜色',
            renderName: 'aColor'
          },
          enableWatermark: {
            label: '水印',
            renderName: 'aRadioGroup',
            options: [
              { label: '开启', value: true },
              { label: '关闭', value: false },
            ],
          },
          showCopyright: {
            label: '底部信息',
            renderName: 'aRadioGroup',
            options: [
              { label: '开启', value: true },
              { label: '关闭', value: false },
            ],
          },
          copyrightTime: {
            label: '日期'
          },
          copyrightCompany: {
            label: '公司名称'
          },
          copyrightDomain: {
            label: '公司网址'
          },
          copyrightFiling: {
            label: '备案'
          },
          button: {
            renderType: 'slot'
          }
        }
      }
    },
    watch: {
      value: {
        handler(val) {
          this.show = val
        },
        deep: true,
        immediate: true
      },
      show(val) {
        this.$emit('input', val)
      }
    },
    mounted() {
      this.form = this.$store.state.setting
    },
    methods: {
      
    }
  }
</script>
