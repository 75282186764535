<template>
  <div/>
</template>

<script>
  import watermark from 'watermark-dom'
  export default {
    name: 'Watermark',
    watch: {
      '$store.state.setting.enableWatermark': {
        handler(val) {
          if (val) {
            // 水印更多设置请查看 https://github.com/saucxs/watermark-dom
            watermark.init({
              watermark_txt: `${this.$store.state.user.userInfo.realname}${this.$store.state.user.userInfo.updated_at}`,
              watermark_width: 200,
              watermark_x: 0,
              watermark_y: 0,
              watermark_x_space: 100,
              watermark_y_space: 100,
              watermark_alpha: 0.1
            })
          } else {
            // watermark.remove 有 bug，改用设置空字符串水印来达到移除效果
            watermark.init({
              watermark_txt: ' '
            })
          }
        },
        immediate: true
      }
    }
  }
</script>
