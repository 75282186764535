<template>
  <div class="bg-white flex justify-between align-item-center">
    <div class="p8 tabs overflowX-auto" ref="tabs">
      <draggable v-model="list" :animation="300">
        <transition-group name="tabbar" class="flex pr">
          <div
            :ref="`tab-${item.name}`"
            v-for="(item, index) in list"
            :key="index"
            class="flex align-item-center txt-14 pointer mr8 p8 br8 justify-between item"
            style="transition: 1s"
            :style="{ minWidth: '140px', background: $route.name === item.name ? $store.state.setting.tabBackground : '', color: $route.name === item.name ? $store.state.setting.tabTextColor : '' }"
            @click="$route.name !== item.name ? $router.push({ path: item.path, query: item.query }) : ''"
          >
            <a-dropdown :trigger="['contextmenu']">
              <div class="flex justify-between wper">
                {{ item.meta.title }}
                <div>
                  <transition enter-active-class="animate__animated animate__rotateIn" leave-active-class="animate__animated animate__rotateOut">
                    <a-icon v-if="$route.name === item.name" type="star" theme="filled" style="color: #fde800;" class="pointer" @click.stop="onClose(index)" />
                  </transition>
                  <a-icon type="close" class="pointer" @click.stop="onClose(index, 1)" v-if="list.length > 1" />
                </div>
              </div>
              <a-menu slot="overlay">
                <a-menu-item><a @click="onClose(index, 2)">关闭右边</a></a-menu-item>
                <a-menu-item><a @click="onClose(index, 3)">关闭其他</a></a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </transition-group>
      </draggable>
    </div>
    <a-dropdown class="mx8">
      <a-icon type="down-square" class="txt-20 pointer" />
      <a-menu slot="overlay">
        <a-menu-item v-for="(item, index) in list" :key="index">
          <div
            @click="$route.name !== item.name ? $router.push({ path: item.path, query: item.query }) : ''"
            :style="{ color: $route.name === item.name ? $store.state.setting.tabTextColor : '' }"
            class="flex justify-between align-item-center w150">
            {{ item.meta.title }}
            <a-icon type="close" class="pointer" @click.stop="onClose(index, 1)" v-if="list.length > 1" />
          </div>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>
  import draggable from 'vuedraggable'
  export default {
    name: 'Tabs',
    components: { draggable },
    data() {
      return {
        list: []
      }
    },
    watch: {
      '$route': {
        handler(val) {
          const index = this.list.findIndex(item => {
            return item.name === val.name
          })
          if (index < 0) {
            this.list.push(val)
          } else {
            this.list[index] = val
          }
          this.$nextTick(() => {
            const ref = this.$refs[`tab-${val.name}`]
            this.scrollTo(ref[0].offsetLeft)
          })
        },
        deep: true,
        immediate: true
      },
    },
    methods: {
      scrollTo(offsetLeft) {
        this.$refs.tabs.scrollTo({
          left: offsetLeft - 150,
          behavior: 'smooth'
        })
      },
      onClose(index, val) {
        if (val === 1) {
          this.list.splice(index, 1)
          // this.$router.push(this.list[this.list.length - 1].path)
          this.$router.push({ path: this.list[this.list.length - 1].path, query: this.list[this.list.length - 1].query })
        } else if (val === 2) {
          this.list.splice(index + 1, 99)
        } else if (val === 3) {
          this.list = this.list.filter((v, k) => k === index)
          // this.$router.push(this.list[this.list.length - 1].path)
          this.$router.push({ path: this.list[this.list.length - 1].path, query: this.list[this.list.length - 1].query })
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .tabbar-enter,
  .tabbar-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
  .tabbar-enter-active {
    transition: 0.5s;
  }
  .tabbar-leave-active {
    transition: 0.3s;
  }
  .tabs {
    .item:hover {
      background: rgba(180, 177, 177, 0.1);
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>