<template>
  <div
    class="header-container hper flex justify-between py8 px12"
    :style="{ background: $store.state.setting.headerBackgroundColor, color: $store.state.setting.headerTextColor }"
  >
    <div class="flex align-item-center">
      <!--<img src="@/assets/img/logo.png" class="hper" />-->
      <div class="txt-20 ls1 font-w600 ml8">后台管理系统</div>
      <div class="flex hper ml50">
        <div
          v-for="(item,index) in menus"
          :key="index"
          style="min-width: 80px;"
          class="mr16"
          @click="menuClick(item.url,item,index)"
        >
          <a-dropdown>
            <div
              class="flex hper pointer px8 br4 flex-direction-column align-item-center justify-center hover-bg"
              :style="{ background: isMenuActive === index ? 'rgba(255,255,255,0.2)' : '' }"
            >
              <a-icon :type="item.icon" class="mb5" />
              <span>{{ item.name }}</span>
            </div>
            <el-menu
              v-if="!$store.state.setting.showMenu"
              slot="overlay"
              size="small"
              mode="horizontal"
              unique-opened
              menu-trigger="hover"
              class="w200 dropMenu"
              style="box-shadow: 0 0 10px"
            >
              <item-menu :row="v" v-for="v in item.children" />
            </el-menu>
          </a-dropdown>
        </div>
      </div>
    </div>
    <a-space :size="20" class="flex align-item-center">
      <span title="设置主题颜色" @click="showSetting = true" class="pointer">设置主题 <a-icon type="setting" /></span>
      <span class="pointer" title="驾驶舱大屏" onclick="window.open('/screen/','_blank')">大屏 <a-icon type="pie-chart" /></span>
      <span class="pointer" title="思维导图" onclick="window.open('/xmind/','_blank')">思维导图 <a-icon type="apartment" /></span>
      <a-popover placement="bottom" trigger="click">
        <div slot="content">
          <div class="tabs">
            <a-tabs size="small" v-model="selectMessage">
              <a-tab-pane :key="1" tab="未读消息(10)"></a-tab-pane>
              <a-tab-pane :key="2" tab="全部消息(3)"></a-tab-pane>
              <a-tab-pane :key="3" tab="待办信息(1)"></a-tab-pane>
            </a-tabs>
          </div>
          <div style="max-height: 200px; overflow-y: auto">
            <div class="flex pointer align-item-center hover py10 pr10" style="border-bottom: 1px solid #f1f1f1">
              <a-icon type="message" class="text-primary mr8" />
              <div>
                <div>你收到了信息你收到了信息你收到了信息</div>
                <div class="text-slate-3">2023-09-09</div>
              </div>
            </div>
            <div class="flex pointer align-item-center hover py10" style="border-bottom: 1px solid #f1f1f1">
              <a-icon type="message" class="text-primary mr8" />
              <div>
                <div>你收到了信息</div>
                <div class="text-slate-3">2023-09-09</div>
              </div>
            </div>
            <div class="flex pointer align-item-center hover py10" style="border-bottom: 1px solid #f1f1f1">
              <a-icon type="message" class="text-primary mr8" />
              <div>
                <div>你收到了信息</div>
                <div class="text-slate-3">2023-09-09</div>
              </div>
            </div>
            <div class="flex pointer align-item-center hover py10" style="border-bottom: 1px solid #f1f1f1">
              <a-icon type="message" class="text-primary mr8" />
              <div>
                <div>你收到了信息</div>
                <div class="text-slate-3">2023-09-09</div>
              </div>
            </div>
            <div class="flex pointer align-item-center hover py10" style="border-bottom: 1px solid #f1f1f1">
              <a-icon type="message" class="text-primary mr8" />
              <div>
                <div>你收到了信息</div>
                <div class="text-slate-3">2023-09-09</div>
              </div>
            </div>
          </div>
          <div class="text-slate-3 pt10 pointer text-center" @click="$router.push('messageManage')">进入消息列表</div>
        </div>
        <a-badge dot>
          <a-icon type="bell" title="消息" class="txt-16 pointer animate__animated animate__swing animate__infinite animate__delay-2s " />
        </a-badge>
      </a-popover>
      <a-popover placement="bottom" trigger="click">
        <div slot="content" class="w150" style="max-height: 200px; overflow-y: auto">
          <a-space :size="10" direction="vertical" class="wper">
            <div class="flex justify-between align-item-center">
              <span><a-icon type="user" class="pointer mr5" />用户列表</span>
              <a-icon type="close" class="pointer" />
            </div>
            <div class="flex justify-between align-item-center">
              <span>菜单管理</span>
              <a-icon type="close" class="pointer" />
            </div>
            <div class="flex justify-between align-item-center">
              <span>DEMO</span>
              <a-icon type="close" class="pointer" />
            </div>
          </a-space>
        </div>
        <a-icon type="star" title="收藏" class="pointer" />
      </a-popover>
      <a-icon title="全屏" :type="isScreenfull ? 'fullscreen' : 'fullscreen-exit'" @click="onScreenfull" />
      <a-icon title="刷新" class="pointer" type="reload" @click="$emit('clickReload')" />
      <a-dropdown class="down">
        <a
          class="ant-dropdown-link block txt-16"
          :style="{ color: $store.state.setting.headerTextColor }"
        >
          <a-avatar
            class="br20 bg-white mr8"
            shape="square"
            :size="30"
            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
          />
          {{ userName }}
          <a-icon type="down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a @click="showChangePsd = true"><a-icon type="lock" /> 修改密码</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="showInfo = true"><a-icon type="edit" /> 修改资料</a>
          </a-menu-item>
          <!--<a-menu-item>
            <a @click="signOut"><a-icon type="logout" /> 退出</a>
          </a-menu-item>-->
        </a-menu>
      </a-dropdown>
      <a-icon title="退出登录" type="poweroff" @click="signOut" class="txt-15 pointer" />
    </a-space>
    <change-psd v-if="showChangePsd" v-model="showChangePsd" />
    <change-info v-if="showInfo" v-model="showInfo" />
    <change-setting v-if="showSetting" v-model="showSetting" />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import ChangePsd from "./changePsd";
  import ChangeInfo from "./changeInfo";
  import ChangeSetting from "./changeSetting";
  import itemMenu from "./itemMenu";
  import screenfull from 'screenfull'
  export default {
    name: 'headerContainer',
    components: {ChangeSetting, ChangeInfo, ChangePsd, itemMenu},
    computed: {
      ...mapGetters({
        menus: 'GetTopLevelMenus',
        userName:'GetUserName'
      })
    },
    data() {
      return {
        showChangePsd: false,
        showInfo: false,
        showSetting: false,
        showCollect: false,
        isMenuActive: 0,
        isScreenfull: true,
        /* 消息 */
        selectMessage: 1
      }
    },
    methods: {
      onScreenfull() {
        if (!screenfull.isEnabled) {
          this.$message({ message: '你的浏览器不支持全屏', type: 'warning' })
          return false
        }
        this.isScreenfull = !this.isScreenfull
        screenfull.toggle()
      },
      menuClick(key,item,index) {
        // console.log(JSON.parse(JSON.stringify(this.menus)))
        this.isMenuActive = index;
        let menus = this.menus.find(n => n.url === key)
        if (!menus.children) {
          this.$message.warning('该模块下暂时没有菜单，请添加！')
          return false
        }
        this.$store.commit('SET_LEFT_MENUS', [])
        this.$nextTick(() => {
          this.$store.commit('SET_LEFT_MENUS', menus.children)
        })
      },
      signOut(){
        this.$confirm({
          title: '提示',
          content: '真的要注销登录吗 ?',
          onOk :() => {
            this.$store.dispatch('Logout').then(() => {
              this.$message.warning('注销登录成功!');
              this.$router.push('/login')
            })
          }
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
  .hover-bg:hover {
    background: rgba(255,255,255,0.2)
  }
  .dropMenu {
    ::v-deep .el-submenu__title {
      height: auto;
      line-height: inherit;
      padding: 8px;
    }
    ::v-deep .el-menu-item {
      height: auto;
      line-height: inherit;
      padding: 8px;
    }
    ::v-deep .el-submenu__title:hover i, ::v-deep .el-menu-item:hover i {
      transform: scale(1.5);
    }
    ::v-deep .el-submenu__title i, ::v-deep .el-menu-item i {
      transition: 0.2s;
    }
  }
  .hover:hover {
    color: #4099ff;
  }
  .tabs ::v-deep .ant-tabs-nav .ant-tabs-tab-active {
    background: none !important;
  }
</style>