<template>
  <a-layout style="height: 100vh">
    <transition name="header">
      <a-layout-header v-if="$store.state.setting.showHeader" class="header zi1 pr">
        <header-container @clickReload="clickReload" />
      </a-layout-header>
    </transition>
    <a-layout class="container px hper wper" :style="$store.state.setting.showHeader ? '' : 'padding-top: 0'">
      <a-layout-sider v-if="$store.state.setting.showMenu">
        <div
          v-if="!$store.state.setting.showHeader"
          class="flex align-item-center p10 text-white"
          :style="{ background: $store.state.setting.headerBackgroundColor, color: $store.state.setting.headerTextColor }"
        >
          <!--<img src="@/assets/img/logo.png" class="h30" />-->
          <div class="txt-14 ls1 font-w600 ml8">后台管理系统</div>
        </div>
        <el-menu
          unique-opened
          class="hper leftMenu overflowY-auto overflowX-hidden"
          :default-active="menuDefaultActive"
          :background-color="$store.state.setting.menuBackgroundColor"
          :text-color="$store.state.setting.menuTextColor"
          :active-text-color="$store.state.setting.menuActiveTextColor"
        >
          <transition-group name="sub-sidebar">
            <item-menu v-if="$store.state.user.leftMenus" :row="item" v-for="(item, index) in $store.state.user.leftMenus" :key="index" />
          </transition-group>
        </el-menu>
      </a-layout-sider>
      <a-layout>
        <top-bar v-if="!$store.state.setting.showHeader" />
        <tabs />
        <a-layout-content>
          <div class="DataConent overflowX-hidden pr">
            <transition name="main" mode="out-in">
                <keep-alive v-if="reload" :include="cacheData">
                    <router-view />
                </keep-alive>
            </transition>
          </div>
        </a-layout-content>
        <a-layout-footer v-if="$store.state.setting.showCopyright" class="p16 text-center text-slate-1">
          {{ $store.state.setting.copyrightTime }}
          <a class="text-slate-1" :href="$store.state.setting.copyrightDomain ? $store.state.setting.copyrightDomain : 'javascript:;'" :target="$store.state.setting.copyrightDomain ? '_blank' : ''">{{ $store.state.setting.copyrightCompany }}</a>
          <a class="text-slate-1 ml5" href="https://beian.miit.gov.cn/" target="_blank">{{ $store.state.setting.copyrightFiling }}</a>
        </a-layout-footer>
      </a-layout>
    </a-layout>
    <watermark v-if="$store.state.user.userInfo.realname" />
  </a-layout>
</template>
<script>
  import HeaderContainer from "./components/headerContainer/index";
  import ItemMenu from "./components/headerContainer/itemMenu";
  import Watermark from "./components/watermark/index";
  import TopBar from "./components/topBar/index";
  import Tabs from "./components/tabs/index"
  export default {
    components: {TopBar, Watermark, ItemMenu, HeaderContainer, Tabs},
    data() {
      return {
        cacheData: [],
        reload: true,
        show: true,
      }
    },
    watch: {
      '$route.path'() {
        if (this.cacheData.indexOf(this.$route.name) === -1) {
          this.cacheData.push(this.$route.name)
        //   // this.reload = false
        //   // this.$nextTick(() => {
        //   //   this.reload = true
        //   // })
        }
      }
    },
    computed: {
      menuDefaultActive() {
        return this.searchMenuId(this.$store.state.user.leftMenus).toString()
      }
    },
    mounted() {
      // this.$c(this.$store.state.setting)
      window.addEventListener("keydown",(e) => {
        if (e.key === 'F5') {
          this.clickReload()
          e.preventDefault()
        }
      })
    },
    methods: {
      searchMenuId(data, id = '') {
        data.forEach(item => {
          if (item.url === this.$route.name) {
            id = item.id
          } else if (item.children && item.children.length > 0) {
            id = this.searchMenuId(item.children, id)
          }
        })
        return id
      },
      /* 刷新 */
      clickReload(){
        // let reloadName = this.$route.name;
        // let replace_data = '777888';
        // let replace_index
        // let _this = this
        // this.cacheData.forEach((d,i)=>{
        //   if(d === reloadName){
        //     replace_index = i
        //     this.cacheData.splice(i,1,replace_data);
        //   }
        // })
        this.reload = false;
        this.$nextTick(() => {
          // _this.cacheData.splice(replace_index,1,reloadName);
          this.reload = true;
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  $g-header-height: 80px;
  .header {
    padding: 0;
    line-height: inherit;
    height:#{$g-header-height};
  }
  .DataConent{
    background: white;
    height: calc(100% - 16px);
    margin: 16px 16px 0 16px;
    overflow-y:auto;
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(31,48,95,0.20);
  }
  // 头部动画
  .header-enter-active, .header-leave-active, .container {
    transition: 0.2s;
  }
  .header-enter {
    transform: translateY(-#{$g-header-height});
  }
  .header-leave-to {
    transform: translateY(-#{$g-header-height});
  }
  .container {
    padding-top: #{$g-header-height};
  }

  // 次侧边栏动画
  .sub-sidebar-enter-active {
    transition: 0.3s;
  }
  .sub-sidebar-enter,
  .sub-sidebar-leave-active {
    opacity: 0;
    transform: translateY(30px) skewY(10deg);
  }
  .sub-sidebar-leave-active {
    position: absolute;
  }


  // 主内容区动画
  .main-enter-active {
    transition: 0.5s;
  }
  .main-leave-active {
    transition: 0.3s;
  }
  .main-enter {
    opacity: 0;
    transform: translateX(50px);
  }
  .main-leave-to {
    opacity: 0;
    transform: translateX(50px);
  }

  .leftMenu {
    ::v-deep .el-submenu__title:hover i, ::v-deep .el-menu-item:hover i {
      transform: scale(1.5);
    }
    ::v-deep .el-submenu__title i, ::v-deep .el-menu-item i {
      transition: 0.2s;
    }
    ::v-deep .el-menu-item:hover, ::v-deep .el-submenu__title:hover {
      background: rgba(0,0,0,0.1) !important;
    }
  }
</style>