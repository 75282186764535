<template>
  <a-modal
    title="修改资料"
    centered
    v-model="show"
    @ok="meansOk"
    :confirmLoading="loading"
    :maskClosable="false">
    <a-form-model
      ref="ruleForm"
      :model="meansForm"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-model-item label="姓名" prop="realname">
        <a-input v-model="meansForm.realname" />
      </a-form-model-item>
      <a-form-model-item label="身份证号" prop="idcard">
        <a-input v-model="meansForm.idcard" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="mobile">
        <a-input v-model="meansForm.mobile" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
  export default {
    name: 'ChangeInfo',
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        show: false,
        meansForm: {},
        rules: {
          realname: [{ required: true, message: '请填写姓名!'}],
          idcard: [{ required: true, message: '请填写身份证号!'}],
          mobile: [{ required: true, message: '请填写手机号!'}],
        }
      }
    },
    watch: {
      value: {
        handler(val) {
          this.show = val
        },
        deep: true,
        immediate: true
      },
      show() {
        this.$emit('input', this.show)
      }
    },
    mounted() {
      this.getInfo()
    },
    methods: {
      /* 获取用户信息 */
      async getInfo(){
        try {
          this.loading = true
          const res = await this.$request({
            url: this.$API.login.getInfo
          })
          this.meansForm = res
        } finally {
          this.loading = false
        }
      },
      /* 修改用户信息 */
      async meansOk(){
        try {
          await this.$refs.ruleForm.validate()
          this.loading = true
          await this.$request({
            url: this.$API.login.accountEdit,
            method: 'post',
            data: this.meansForm
          })
          this.show = false;
          this.$message.success('修改资料成功!');
          await this.$store.dispatch('GetInfo');
        } finally {
          this.loading = false
        }
      },
    }
  }
</script>