<template>
  <div class="flex justify-end wper bg-white py10 px16 border-bottom">
    <a-space :size="20" class="flex align-item-center">
      <a-icon class="pointer" type="setting" @click="showSetting = true" />
      <a-icon class="pointer" type="reload" @click="$emit('clickReload')" />
      <a-dropdown class="down py10">
        <a class="ant-dropdown-link text-slate-1 txt-16">
          <a-avatar class="br20 bg-white mr8" shape="square" :size="30" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
          {{ userName }}
          <a-icon type="down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a @click="showChangePsd = true"><a-icon type="lock" /> 修改密码</a>
          </a-menu-item>
          <a-menu-item>
            <a @click="showInfo = true"><a-icon type="edit" /> 修改资料</a>
          </a-menu-item>
          <!--<a-menu-item>
            <a @click="signOut"><a-icon type="logout" /> 退出</a>
          </a-menu-item>-->
        </a-menu>
      </a-dropdown>
      <a-icon type="poweroff" @click="signOut" class="txt-15 pointer" />
    </a-space>
    <change-psd v-if="showChangePsd" v-model="showChangePsd" />
    <change-info v-if="showInfo" v-model="showInfo" />
    <change-setting v-if="showSetting" v-model="showSetting" />
  </div>
</template>
<script>
  import ChangePsd from "../headerContainer/changePsd";
  import ChangeInfo from "../headerContainer/changeInfo";
  import ChangeSetting from "../headerContainer/changeSetting";
  import {mapGetters} from "vuex";
  export default {
    name: 'TopBar',
    components: {ChangeSetting, ChangeInfo, ChangePsd},
    computed: {
      ...mapGetters({
        userName:'GetUserName'
      })
    },
    data() {
      return {
        showChangePsd: false,
        showInfo: false,
        showSetting: false
      }
    },
    methods: {
      signOut(){
        this.$confirm({
          title: '提示',
          content: '真的要注销登录吗 ?',
          onOk :() => {
            this.$store.dispatch('Logout').then(() => {
              this.$message.warning('注销登录成功!');
              this.$router.push('/login')
            })
          }
        })
      },
    }
  }
</script>