<template>
  <a-modal
    title="修改密码"
    centered
    v-model="show"
    @ok="changePsdOK"
    :confirmLoading="loading"
    :maskClosable="false">
    <a-form-model
      ref="psdForm"
      :model="psdForm"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-model-item label="老密码" prop="oldPwd">
        <a-input type="password" v-model="psdForm.oldPwd" />
      </a-form-model-item>
      <a-form-model-item label="新密码" prop="newPwd">
        <a-input type="password" v-model="psdForm.newPwd" />
      </a-form-model-item>
      <a-form-model-item label="确认密码" prop="newPwd2">
        <a-input type="password" v-model="psdForm.newPwd2" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
  export default {
    name: 'ChangePsd',
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== this.psdForm.newPwd) {
          callback('两次密码不同!');
        } else {
          callback();
        }
      }
      return {
        show: false,
        psdForm: {},
        rules: {
          oldPwd: [{ required: true, message: '请填写之前密码!'}],
          newPwd: [
            { required: true, message: '请填写新密码!'}
          ],
          newPwd2: [
            { required: true, message: '请再次填写新密码!'},
            { validator: compareToFirstPassword }
          ],
        }
      }
    },
    watch: {
      value: {
        handler(val) {
          this.show = val
        },
        deep: true,
        immediate: true
      },
      show() {
        this.$emit('input', this.show)
      }
    },
    methods: {
      /* 修改密码 */
      async changePsdOK(){
        try {
          await this.$refs.psdForm.validate()
          this.loading = true
          await this.$request({
            url: this.$API.login.changeAccountPsd,
            method: 'post',
            data: this.psdForm
          })
          this.show = false;
          this.$message.success('修改密码成功!')
          await this.$router.push('/login')
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>